import Logo from "./logo";
import {slide as Menu} from "react-burger-menu";
import {Link} from "gatsby";
import React from "react";
import styled from "styled-components";
import {lightGrey} from "../../styles/colors";

const StyledHeaderMb = styled.header`
    padding: 15px;
    border-bottom: 1px solid ${lightGrey};
    background: #fff;
    z-index: 10;
    position: fixed;
    top: 0px;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    min-height: 60px;

    @media (min-width: 960px) {
      display: none;
    }

    a {
      color: #000000;
      text-decoration: none;
    }
`

const StyledLogo = styled.div`
  height: 35px;
  width: 40px;
  position: absolute;
`

var styles = {
  bmBurgerButton: {
    position: 'fixed',
    right: '15px',
    top: '18px',
    width: '25px',
    height: '25px'
  },
  bmBurgerBars: {
    background: '#373a47'
  },
  bmBurgerBarsHover: {
    background: '#a90000'
  },
  bmCrossButton: {
    height: '24px',
    width: '24px',
    right: '30px',
    top: '15px'
  },
  bmCross: {
    background: '#bdc3c7'
  },
  bmMenuWrap: {
    position: 'fixed',
    height: '100%',
    top: '0',
    width: '80%',
  },
  bmMenu: {
    background: '#fff',
    padding: '2.5em 1.5em 0',
    fontSize: '1.15em'
  },
  bmMorphShape: {
    fill: '#373a47'
  },
  bmItemList: {
    color: '#b8b7ad',
    padding: '0.8em'
  },
  bmItem: {
    display: 'block',
    color: 'black',
    margin: '40px 0',
    textDecoration: 'none',
    fontWeight: '300',
    outline: 'none',
    textAlign: 'right'
  },
  bmOverlay: {
    background: 'rgba(0, 0, 0, 0.3)',
    top: '0',
    left: '0'
  }
}

const activeStyle = {
  borderBottom: '1px solid',
  paddingBottom: '5px'
}

type BurgerIconType = {
  isOpen?: boolean
}

function BurgerIcon({isOpen = false}: BurgerIconType) {
  return (isOpen ? <svg id="hamburger" viewBox="0 0 24 24">
    <path d="M0 6h24M0 12h24M0 18h24" fill="none" stroke="currentColor" color="#000000"
          strokeMiterlimit="10" strokeWidth="2" fontSize="21px"/>
  </svg> : <svg id="close" viewBox="0 0 18 24">
    <path d="M17 4l-8 8 8 8M1 4l8 8-8 8" fill="none" stroke="currentColor"
          strokeMiterlimit="10" strokeWidth="2" fontSize="21px"></path>
  </svg>)
}

export default function MobileHeader() {
  return (
    <StyledHeaderMb>
      <Link aria-label="home" to="/">
        <StyledLogo><Logo/></StyledLogo>
      </Link>
      <Menu
        customBurgerIcon={<BurgerIcon isOpen/>}
        customCrossIcon={<BurgerIcon/>}
        right
        styles={styles}
      >
        <li><Link tabIndex={-1} activeStyle={activeStyle} to="/projects/">Projects</Link></li>
        <li><Link tabIndex={-1} activeStyle={activeStyle} to="/services/">Services</Link></li>
        <li><Link tabIndex={-1} activeStyle={activeStyle} to="/about/">About us</Link></li>
        <li><Link tabIndex={-1} activeStyle={activeStyle} to="/contact/">Contact us</Link></li>
      </Menu>
    </StyledHeaderMb>
  )
}
