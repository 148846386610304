import React from "react";

export default function Logo() {
    return (
        <svg
            version="1.1" id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            width="100%"
            height="100%"
            viewBox="0 0 128 120"
            enableBackground="new 0 0 128 120"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            xmlSpace="preserve"
        >
            <g>
                <g>
                    <polygon fill="#3E3935" points="127.999,85.737 127.999,80.085 5.471,80.085 5.471,10.885 0,10.885 0,85.737 "></polygon>
                    <polygon fill="#3E3935" points="61.176,74.625 66.831,74.625 66.831,5.472 127.999,5.472 127.999,0 0,0 0,5.472 61.176,5.472 "></polygon>
                </g>
                <g>
                    <path fill="#3E3935" d="M10.236,105.58c-2.723-0.328-5.034-0.863-5.034-2.847c0-1.693,1.525-3.017,4.694-3.017c2.447,0,4.595,1.241,5.358,2.518l3.407-1.726c-1.739-3.491-5.367-4.504-8.915-4.504c-4.166,0.046-8.757,1.94-8.757,6.601c0,5.084,4.294,6.308,8.907,6.855c2.979,0.331,5.209,1.197,5.209,3.327c0,2.451-2.519,3.399-5.2,3.399c-2.721,0-5.368-1.114-6.355-3.591L0,114.437c1.695,4.146,5.244,5.548,9.87,5.548c5.032,0,9.492-2.145,9.492-7.196C19.361,107.396,14.958,106.155,10.236,105.58z"></path><path fill="#3E3935" d="M22.738,100.634h7.179v18.733h4.254v-18.733h7.178v-3.88c-6.559,0-12.09,0-18.611,0V100.634z"></path>
                    <path fill="#3E3935" d="M79.961,96.754c-2.805,0-6.104,0-8.918,0v22.613c2.813,0,6.113,0,8.918,0c7.901,0,11.401-5.78,11.312-11.516C91.145,102.277,87.705,96.754,79.961,96.754z M79.961,115.276h-4.658v-14.48h4.658c4.918,0,6.987,3.505,7.063,7.038C87.117,111.529,85.086,115.276,79.961,115.276z"></path>
                    <rect x="95.932" y="96.754" fill="#3E3935" width="4.217" height="22.613"></rect>
                    <path fill="#3E3935" d="M116.404,96.334c-7.835,0-11.641,5.986-11.641,11.933c0,5.938,3.67,11.665,11.641,11.665c7.94,0,11.521-5.847,11.595-11.708C128.062,102.32,124.19,96.334,116.404,96.334z M116.404,116.03c-5.329,0-7.353-3.983-7.433-7.707c-0.104-3.82,2.104-8.111,7.433-8.111c5.316,0,7.466,4.263,7.38,8.095C123.739,112.019,121.721,116.03,116.404,116.03z"></path>
                    <path fill="#3E3935" d="M61.959,109.711c0,3.876-2.022,6.236-5.53,6.236s-5.865-2.184-5.865-6.236V96.754h-4.202v12.957c0,6.806,4.619,10.112,10.027,10.112c5.699,0,9.792-3.427,9.792-10.112V96.754h-4.223V109.711z"></path>
                </g>
            </g>
        </svg>
    )
}
