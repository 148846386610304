import styled from "styled-components";

const Grid = styled.section`
    margin-left: auto;
    margin-right: auto;
    max-width: 100%;
    position: relative;
    width: 100%;

    @media (min-width: 960px) {
      padding-left: 15px;
      padding-right: 15px;
    }

    @media (min-width: 1280px) {
      width: 1280px;
    }
`

export default Grid

export const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
`

type ColProps = {
  small?: number
  medium?: number
  large?: number
  offset?: number
  noGuttersS?: boolean
  noGutters?: boolean
}

export const Col = styled.div<ColProps>`
  float: left;
  padding: 0 ${({ noGuttersS = false}) => noGuttersS ? '0' : '15px' };
  width: ${({ small = 12 }) => (small * 100) / 12}%;

  @media (min-width: 600px) {
    width: ${({ medium = 12 }) => (medium * 100) / 12}%;
  }

  @media (min-width: 960px) {
    width: ${({ large = 24 }) => (large * 100) / 24}%;
    padding: 0 ${({ noGutters }) => noGutters ? '0' : '15px'};
    margin-left: ${({ offset = 0 }) => (offset * 100) / 24}%;
  }
`
