import Logo from "./logo";
import {Link} from "gatsby";
import React from "react";
import styled from "styled-components";
import {lightGrey} from "../../styles/colors";
import Grid, {Row} from "../grid/grid";

const StyledHeaderDt = styled.header`
  display: none;
  align-items: center;
  padding: 15px 0px;
  position: fixed;
  z-index: 10;
  background: white;
  width: 100%;
  border-bottom: 1px solid ${lightGrey};
  justify-content: space-between;
  height: 120px;

  @media (min-width: 960px) {
    display: flex;
  }

  a {
    color: #000000;
    text-decoration: none;
  }
`

const StyledLogo = styled.div`
  height: 70px;
  width: 78px;
`

const StyledGrid = styled(Grid)`
  @media (min-width: 960px) {
    padding: 0 15px;
  }
`

const StyledLink = styled(Link)`
  color: black;
  text-decoration: none;
  margin: 0 0px 0 30px;
`

const Nav = styled.nav`

`

const activeStyle = {
  borderBottom: '3px solid',
  paddingBottom: '10px'
}

const StyledRow = styled(Row)`
  justify-content: space-between;
  align-items: center;
`

export default function DesktopHeader() {
  return (
    <StyledHeaderDt>
      <StyledGrid>
        <StyledRow>
          <Link aria-label="home" to="/">
            <StyledLogo><Logo/></StyledLogo>
          </Link>
          <Nav>
            <StyledLink
              partiallyActive
              activeStyle={activeStyle}
              to="/projects/"
            >
              Projects
            </StyledLink>
            <StyledLink activeStyle={activeStyle} to="/services/">
              Services
            </StyledLink>
            <StyledLink activeStyle={activeStyle} to="/about/">About
              us</StyledLink>
            <StyledLink activeStyle={activeStyle} to="/contact/">
              Contact us
            </StyledLink>
          </Nav>
        </StyledRow>
      </StyledGrid>
    </StyledHeaderDt>
  )
}
