import React, {PropsWithChildren} from "react"
import Header from "../header/header"
import "./layout.css"
import styled from "styled-components";
import {lightGrey} from "../../styles/colors";

type LayoutProps = PropsWithChildren<{}>

const Main = styled.main`
  padding-top: 60px;

  @media (min-width: 960px) {
    padding-top: 120px;
  }
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`

const Footer = styled.footer`
    border-top: 1px solid ${lightGrey};
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 60px 0;
    flex-shrink: 0;
    flex-direction: column;
    font-weight: 100;
    font-size: 12px;

    a {
      color: #000000;
    }
`

const StyledLink = styled.a`
  padding-bottom: 10px;
  display: block;
`

const StyledText = styled.div`
  padding-bottom: 10px;
`

const Layout = ({children}: LayoutProps) => {
  return (
    <Container>
      <Header/>
      <Main id="content">{children}</Main>
      <Footer>
        <StyledText>© 2020 LTStudio. All rights reserved.</StyledText>
        <StyledLink href="tel:+380665754708">
          <span className="data-label">tel.:</span><span className="data-tel">+38 066 575 47 08</span>
        </StyledLink>
        <StyledLink href="mailto:olena.t@ltstudio.com.ua">olena.t@ltstudio.com.ua</StyledLink>
      </Footer>
    </Container>
  )
}

export default Layout
